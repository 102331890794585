
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { Audio } from '@/entities/audio/Audio';
import { Location } from '@/entities/Location';
import { User } from '@/entities/User';

@Component({
  computed: mapGetters([
    'location',
    'user'
  ])
})
export default class AudioUploaderDialog extends Vue {
  @Prop()
  value!: Boolean;

  @Prop({ default: false })
  editing!: boolean;

  @Prop({ default: null })
  audio!: Audio | null

  @Prop({ default: () => {}})
  refresh!: () => {}

  valid = false;
  categories: string[] = [];

  name?: string = "";
  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string)  => (v && v.length >= 6) || 'Name must be more than 5 characters',
    (v: string)  => (v && v.length <= 64) || 'Name must be less than 65 characters'
  ];

  file: File | null = null;
  fileRules = [
    (v: string)  => !!v || 'Music is required',
    (v: File) => {
      const MbInBytes = 1024**2 // 1 Mb
      if(v && v.size > (MbInBytes*100) /** 100Mb */ )
      {
        return 'Audio file is larger than 100 Mb'
      }
      else{
        return true;
      }
    }
  ];

  global = false;

  location!: Location;
  user!: User;

  $refs!: {
    form: HTMLFormElement
  }

  get show() {
    return this.value;
  }

  set show(value) {
    if (this.audio === null) {
      this.$refs.form.reset();
      this.name = "";
      this.file = null;
    }

    this.$emit('input', value);
  }

  @Watch("audio")
  audioChanged() {
    this.name = this.audio!.name;
    this.file = new File([""], this.audio!.file_name);
  }

  click() {
    if (this.audio === null) {
      this.save();
    } else {
      this.edit();
    }
  }

  onChange(file: File) {
    if (this.name === undefined) return;

    if (this.name.trim() === "") {
      this.name = file.name.split('.').slice(0, -1).join('.');
    }
  }

  async save() {
    if (this.$refs.form.validate()) {
      const form = this.$refs.form.$el;
      const data = new FormData(form);

      if (this.global === false) {
        data.set("location", this.location.uuid);
      }
        this.$audios.upload(data).then(() => {
        this.show = false;
        this.refresh();
      });
    }
  }

  async edit() {
    if (this.$refs.form.validate()) {
      this.$audios.edit(this.audio!.file_name, {
        name: this.name,
      }).then(() => {
        this.show = false;
        this.refresh();
      });
    }
  }
}
