/**
 * Used to fetch, upload and edit images
 * Zoey Désautels (zoey.desautels@konek.ai), 2020
 */

import axios from 'axios';
import { bus, Colors } from "@/plugins/events";

import { Image } from '@/entities/images/Image';

/**
 * ImageData that can be updated
 */
export interface ImageData {
  name?: string,
  category?: string,
  location?: string
}

export default class Images {
  /**
   * List all the images available to the user
   */
  async list(): Promise<Image[]> {
    try {
      const response = await axios.get("/v1/images");
      const images: Image[] = response.data.images; 

      return images;
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't fetch images",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Upload an image
   */
  async upload(data: any): Promise<Image> {
    try {
      const response = await axios.post("/v1/images/upload", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      bus.$emit("show-snack", {
        text: "Image was uploaded.",
        color: Colors.SUCCESS
      });

      const image: Image = response.data.image;

      return image;
    } catch {
      bus.$emit("show-snack", {
        text: "Image couldn't be uploaded.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
   * Edit an image
   */
  async edit(file_name: string, data: ImageData) {
    try {
      await axios.patch(`/v1/images/${file_name}`, data);

      bus.$emit("show-snack", {
        text: "Image was updated.",
        color: Colors.SUCCESS
      });
    } catch {
      bus.$emit("show-snack", {
        text: "Couldn't update the image!",
        color: Colors.ERROR
      });

      throw Error();
    }
  }

  /**
  * Upload an image and resize it
  * @param w Target width
  * @param h Target height
  * @param keepImageRatio Should we keep the image's ratio ?
  */
  async uploadAndResize(data: any, w: number, h: number, keepImageRatio: boolean): Promise<Image> {
    try {
      const response = await axios.post("/v1/images/upload-resize", data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params: {
          w,
          h,
          keepImageRatio,
        }
      });

      bus.$emit("show-snack", {
        text: `Image was uploaded and resized to {${w} x ${h}}`,
        color: Colors.SUCCESS
      });

      const image: Image = response.data.image;

      return image;
    } catch {
      bus.$emit("show-snack", {
        text: "Image couldn't be uploaded or resized.",
        color: Colors.ERROR
      });

      throw Error();
    }
  }
}