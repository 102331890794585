
import { UnifiUrlParams } from '@/plugins/services/Unifi/unifi';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator';
import { ConnectionFormInterface } from './ConnectionFormInterface';
import { DEFAULT_THEME, getTextColor, UnifiColorTheme } from './UnifiColorTheme';
@Component({})
export default class PasswordForm extends Vue implements ConnectionFormInterface  {
  @Prop() urlParams!: UnifiUrlParams;

  @Ref('form') readonly form!: (Vue & { validate: () => boolean });
  @Prop({default: DEFAULT_THEME}) theme!: UnifiColorTheme;
  @Prop() connection_duration!: number;


  didAcceptTerms = false;
  invalidTerms = false;
  connectionError = false;
  errorMessage = '';
  get link_style(){
    return {
      '--color': this.theme.secondary,
      '--color-hover': this.theme.accent
    }
  }
  get primaryTextColor() {
    return getTextColor(this.theme.primary!);
  }
  termsClicked() {
    this.$emit("open-terms");
  }
  loginInfos = {
    password: ""
  };
  valid = true;
  passwordRules = [
    (v: string) => !!v || this.$t("captive.password.rules[0]"),
    (v: string) => (v && v.length >= 1) || this.$t("captive.password.rules[1]"),
  ];
  submit() {
    // console.log("submitted", this.loginInfos);
  }
  async connect(){
    this.invalidTerms = !this.didAcceptTerms;
    let valid = await this.validate();
    if(valid && this.didAcceptTerms){
      // let duration = this.connection_duration * 24 * 60;
      this.$emit("connect", {minutes:this.connection_duration * 24 * 60, email_validation:false});
    }
  }

  async validate() {
    let validForm = this.form.validate();
    if (!validForm || this.invalidTerms)return false;
    let res = await this.$unifi.validatePassword(this.urlParams.siteID, this.loginInfos.password);
    if(!res){
      this.connectionError = true;
      this.errorMessage = this.$t("captive.password.invalid") as string;
     return false;
    }
    return validForm&&res;
  }
}
