
import axios from 'axios';
import { mapGetters } from 'vuex';
import Vue from 'vue'
import store from '@/store';
import { bus } from "@/plugins/events";
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

import { Image } from '@/entities/images/Image';
import { Audio } from '@/entities/audio/Audio';
import { Location } from '@/entities/Location';

@Component({
  computed: mapGetters([
    'location',
    'locations'
  ])
})
export default class WelcomeForm extends Vue {
  @Ref('form') form!: (Vue & { validate: () => boolean });

  @Prop({ required: true })
  images!: Image[];

  filtered_images: Image[] = [];

  locations!: Location[];
  location!: Location;

  bannerImage = "";
  radioChannel = "";
  musicURL = "";
  musicEnabled = false;
  audios: Audio[] = [];
  music_type = 0;

  @Watch('location')
  watchLocation() {
    this.loadLocation();
    this.loadImages();
  }

  @Watch('images')
  watchImages() {
    this.loadImages();
  }

  mounted() {
    this.loadLocation();
    this.loadImages();
    this.loadAudios();

    if (this.musicURL != "") {
      this.music_type = 1;
    } else if (this.radioChannel != "") {
      this.music_type = 0;
    }
  }

  async loadLocation() {
    this.bannerImage = this.location.banner_url ?? "";
    this.musicEnabled = this.location.welcome_music_enabled;
    this.radioChannel = this.location.welcome_music_channel;
    this.musicURL = this.location.welcome_music_url;
  }

  async loadImages() {
    this.filtered_images = this.images.filter((image) => {
      if (image.category.toLowerCase() !== "banner") {
        return false;
      }

      if (image.location?.uuid !== this.location.uuid) {
        return false;
      }

      return true;
    });
  }

  async loadAudios() {
    this.$audios.list().then((audios) => {
      this.audios = audios.filter((audio) => audio.location != null && audio.location != this.location);
    });
  }

  change_music_type() {
    if (this.music_type == 0) {
      this.musicURL = "";
    } else if (this.music_type == 1) {
      this.radioChannel = "";
    }
  }

  async save() {
    if (this.form.validate()) {
      try {
        await this.$locations.edit(this.location.uuid, {
          banner_url: this.bannerImage,
          welcome_music_enabled: this.musicEnabled,
          welcome_music_channel: this.radioChannel,
          welcome_music_url: this.musicURL
        });

        bus.$emit("show-snack", {
          text: "Hotel's welcome screen was updated!",
          color: 'success'
        });

        const response = await axios.get("/v1/locations");

        store.commit('setLocations', {
          locations: response.data.locations
        });
      } catch(err) {
        // do nothing
      }
    }
  }
}
