export interface UnifiColorTheme {
  /// The primary color of the theme.
  primary?: string;
  /// The secondary color of the theme.
  secondary?: string;
  /// The accent color of the theme.
  accent?: string;

}
export const DEFAULT_THEME: UnifiColorTheme = {
  primary: '#3f51b5',
  secondary: '#f50057',
  accent: '#ff4081',
};

export function getTextColor (primary: string): string {
  // Get background color hex value. '#' is optional.
  let hex = (primary!.charAt(0) === '#') ? primary!.substring(1, 7) : primary!;

  // Convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // By this point, it should be 6 characters
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  let r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

  // Return light or dark class based on contrast calculation
  return ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ? "dark" : "white";
}
