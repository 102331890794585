
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// import { categories } from '@/constants';

import { PdfMenu } from '@/entities/pdfMenu/PdfMenu';
import { Location } from '@/entities/Location';
import { User } from '@/entities/User';

@Component({
  computed: ([
    'location',
    'user'
  ])
})
export default class MenuUploaderDialog extends Vue {
  @Prop()
  value!: Boolean;

  @Prop({ default: false })
  editing!: boolean;

  @Prop({ default: null })
  menu!: PdfMenu | null

  @Prop({ default: () => {}})
  refresh!: () => {}

  valid = false;
  categories: string[] = [];

  name?: string = "";
  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string)  => (v && v.length >= 6) || 'Name must be more than 5 characters',
    (v: string)  => (v && v.length <= 64) || 'Name must be less than 65 characters'
  ];

  // category = "";
  // categoryRules = [
  //   (v: string) => !!v || 'Category is required'
  // ];
  
  file: File | null = null;
  fileRules = [
    (v: string)  => !!v || 'PdfMenu is required'
  ];

  // global = false;

  location!: Location;
  user!: User;

  $refs!: {
    form: HTMLFormElement
  }

  mounted() {
    // this.categories = categories;
  }

  get show() {
    return this.value;
  }

  set show(value) {
    if (this.menu === null) {
      this.$refs.form.reset();
      this.name = "";
      // this.category = "";
      this.file = null;
    }
    
    this.$emit('input', value);
  }

  @Watch("menu")
  imageChanged() {
    this.name = this.menu!.name;
    this.file = new File([""], this.menu!.file_name);
  }

  click() {
    if (this.menu === null) {
      this.save();
    } else {
      this.edit();
    }
  }

  onChange(file: File) {
    if (this.name === undefined) return;

    if (this.name.trim() === "") {
      this.name = file.name.split('.').slice(0, -1).join('.');
    }
  }

  async save() {
    if (this.$refs.form.validate()) {
      const form = this.$refs.form.$el;
      const data = new FormData(form);

        data.set("location", this.location.uuid);
      
      this.$pdfMenus.upload(data).then(() => {
        this.show = false;
        this.refresh();
      });
    }
  }

  async edit() {
    if (this.$refs.form.validate()) {
      this.$pdfMenus.edit(this.menu!.file_name, {
        name: this.name,
      }).then(() => {
        this.show = false;
        this.refresh();
      });
    }
  }
}
