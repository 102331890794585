
import { bus } from '@/plugins/events';
import store from '@/store';
import axios from 'axios';
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ColorPicker from '../ColorPicker.vue';
import { Location } from '@/entities/Location';
import LoginOptionsInterface from '../unifi/LoginOptionsInterface';
import { PortalSettings } from '@/entities/PortalSettings';
import { Image } from '@/entities/images/Image';

@Component({
  components: {
    ColorPicker
  },
  computed: mapGetters([
    'location'
  ])
})
export default class UnifiPortalForm extends Vue {
  @Ref('form') form!: (Vue & { validate: () => boolean });
  primaryColor = "";
  secondaryColor = "";
  accentColor = '';
  loginOptions: LoginOptionsInterface = {
    room_number: false,
    voucher: false,
    free: false,
    password: false,
  };
  location!: Location
  termsAndConditions = '';
  image_dialog = false;
  free_trial_duration = 60;
  connection_duration = 7;
  redirection_url = "";

  logo_images: Image[] = [];
  banner_images: Image[] = [];

  logoURL = '';
  backgroundImageURL = '';

  @Prop({ required: true })
  images!: Image[];

  @Watch('location')
  watchLocation(){
    this.loadLocation();
    this.loadImages();
  }

  @Watch('images')
  watchImages() {
    this.loadImages();
  }

  mounted() {
    this.loadImages();
    this.loadLocation();
  }

  async loadLocation() {
    const portal_settings = this.location.portalSettings;
    this.primaryColor = portal_settings?.primary_color ?? '#06B569';
    this.secondaryColor = portal_settings?.secondary_color ?? '#0669B5';
    this.accentColor = portal_settings?.accent_color ?? '#FF00FF';
    this.loginOptions = {
      room_number: portal_settings?.room_number_login??false,
      voucher: portal_settings?.voucher_login??false,
      free: portal_settings?.free_login??false,
      password: portal_settings?.password_login??true,
    };
    this.termsAndConditions = portal_settings?.terms ?? '';
    this.logoURL = portal_settings?.logo_url ?? '';
    this.backgroundImageURL = portal_settings?.image_url ?? '';
    this.free_trial_duration = portal_settings?.free_trial_duration ?? 60;
    this.connection_duration = portal_settings?.connection_duration ?? 7;
    this.redirection_url = portal_settings?.redirection_url ?? "https://konek.ai/";
  }

  async loadImages() {
    this.banner_images = this.images.filter((image) => {
      if (image.category.toLowerCase() !== "banner") {
        return false;
      }

      if (image.location && image.location.uuid !== this.location.uuid) {
        return false;
      }

      return true;
    });

    this.logo_images = this.images.filter((image) => {
      if (image.category.toLowerCase() !== "logo") {
        return false;
      }

      if (image.location && image.location.uuid !== this.location.uuid) {
        return false;
      }

      return true;
    });
  }

  clearLogo() {
    this.logoURL = '';
  }

  clearBackgroundImage() {
    this.backgroundImageURL = '';
  }

  async save() {
    if (this.form.validate()) {
      try {
        let settings = {
          location: this.location,
          primary_color: this.primaryColor,
          secondary_color: this.secondaryColor,
          accent_color: this.accentColor,
          password_login: this.loginOptions.password,
          voucher_login: this.loginOptions.voucher,
          free_login: this.loginOptions.free,
          room_number_login: this.loginOptions.room_number,
          terms: this.termsAndConditions,
          logo_url: this.logoURL ?? '',
          image_url: this.backgroundImageURL ?? '',
          free_trial_duration: this.free_trial_duration ?? 60,
          connection_duration: this.connection_duration ?? 7,
          redirection_url: this.redirection_url??'https://konek.ai/'
        };

        if (this.location.portalSettings) {
          let result = await this.$unifi.updatePortalSettings(this.location.siteID.toString(), settings as PortalSettings);
        } else {
          await axios.patch(`/v1/locations/${this.location.uuid}`, {
            portalSettings: settings
          });
        }

        bus.$emit("show-snack", {
          text: "Hotel's captive portal was updated!",
          color: 'success'
        });

        const response = await axios.get("/v1/locations");

        store.commit('setLocations', {
          locations: response.data.locations
        });
      } catch(err:any) {
        const error = err.response.data.message;

        bus.$emit("show-snack", {
          text: error,
          color: 'error'
        });
      }
    }
  }
}
