
import { Kokote, CustomMediaType } from "@/entities/devices/Kokote";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { Image } from "@/entities/images/Image";
import { Location } from "@/entities/Location";
import { DataTableHeader } from "vuetify";
import BannerButton from "@/components/buttons/BannerButton.vue";

import { Video } from "@/entities/videos/Video";
import { bus, Colors } from "@/plugins/events";
import { CombinedRooms, SplittedItem as SplittedRoomNumber } from "../../entities/CombinedRooms";

@Component({
  computed: mapGetters([
    'location'
  ]),
  data: () => ({
    dialog: false,
    image: undefined,
    video: null,
    // banner: undefined,
  }),
  components: {
    BannerButton,
  },
})

export default class RoomsListView extends Vue {
  //#region Variables
  helpDialog = false;
  imageURL = "";

  buttonURL?: string = "";
  buttonType: string = "";

  dialog = false;
  dialogMediaType = "";
  dialogKokote!: Kokote;
  image?: Image;
  images: Image[] = [];
  videos: Video[] = [];

  video?: string | null;

  location!: Location;

  dialogVideoUrl: string | null = null;
  dialogSelectedImage: number = -1;

  selectedRooms: Kokote[] = [];
  multipleSelection_type: CustomMediaType = CustomMediaType.NONE;
  multipleSelection_url: string = "";
  dialogMultiple: Boolean = false;

  selectedImages: number[] = [];
  selectedVideos: number[] = [];

  combinedRooms: CombinedRooms[] = [];
  headersFull: DataTableHeader[] = [
    {
      text: "Room number",
      value: "room.room_number",
      width: "20%",
      align: "start",
    },
    {
      text: "Media Type",
      value: "room.customMedia",
      sortable: false,
    },
  ];

  mediaRules = [
    (v: string) => {
      if (v != undefined && v != "None") {
        return true;
      } else {
        return "Cannot be none";
      }
    },
  ];


  useRegex(input: string) {
    const REGEX = /^(?<type>\w*):?(?<url>.*)$/
    let regexp = new RegExp(REGEX, "g");

    let match = regexp.exec(input);
    return match;
  }

  getButtonType(input: string){
    let match = this.useRegex(input);
    return match?.at(1)
  }

  getButtonURL(input: string){
    let match = this.useRegex(input);
    return match?.at(2)
  }

  mediaTypes = CustomMediaType;
  search = "";



  mediaSearch = "";

  kokotes: Kokote[] = [];
  //#endregion

  //#region Methods
  async save() {
    if (this.dialog === true) return;
    const bannerTypeIsMedia = this.buttonType == "video" || this.buttonType == "image";
    const noBannerUrl = () => !this.buttonURL;
    const missingMedia = bannerTypeIsMedia && noBannerUrl()
    if (missingMedia){
      bus.$emit("show-snack", {
        text: `No media selected`,
        color: Colors.ERROR,
      });
      return
    }

    //if any kokote is missing media, display a message and return
    if (this.getAlerts.includes(true)) {
      bus.$emit("show-snack", {
        text: `Some rooms are missing media`,
        color: Colors.ERROR,
      });
      return;
    }
    let allKokotes = this.allCombined;
    let roomDatas = allKokotes.map((kokote) => {
      if (kokote.custom_media_type == "None") kokote.custom_media_url = "";
      return {
        custom_media_url: kokote.custom_media_url,
        custom_media_type: kokote.custom_media_type,
      };
    });
    await this.$rooms
      .editMany(
        allKokotes.map((kokote) => kokote.room!),
        roomDatas
      )
      .then(() => {
        this.fetchDevices();
      });

      // To avoid 'video:' and 'image:' in the database
      let home_banner;
      // For 'tv'
      if (!bannerTypeIsMedia && this.buttonType) {
        home_banner = this.buttonType;
      }
      // For 'video' and 'image'
      else if (bannerTypeIsMedia && this.buttonURL) {
        home_banner = this.buttonType + ":" + this.buttonURL;
      }
      else {
        home_banner = "tv";
        this.buttonURL = undefined;
      }

    await this.$locations.edit(this.location.uuid, {
      home_banner: home_banner,
      banner_url: this.buttonURL,
    });
    this.selectedRooms = [];
    this.$store.dispatch('loadLocations');
  }

  getVideoName(item:Kokote){
    let indexVideo = this.videos.findIndex(
      (vid) => vid.file_url == item.custom_media_url
    );
    let videoName = this.filteredVideos[indexVideo]?.name
    return videoName
  }
  getImageName(item:Kokote){
    let indexImage = this.images.findIndex(
      (vid) => vid.file_url == item.custom_media_url
    );
    let imageName = this.filteredImages[indexImage]?.name
    return imageName
  }

  async loadImages() {
    this.$images.list().then((images) => {
      this.images = images;
      this.images = this.images.filter((image) => {
        if (image.category.toLowerCase() !== "banner") {
          return false;
        }

        if (image.location?.uuid !== this.location.uuid) {
          return false;
        }

        return true;
      });
      //console.log(this.images);
    });
  }
  async loadVideos() {
    this.$videos.list().then((videos) => {
      this.videos = videos;
      this.videos = this.videos.filter((video) => {
        if (video.location?.uuid == undefined) {
          return true;
        }

        if (video.location?.uuid !== this.location.uuid) {
          return false;
        }

        return true;
      });
    });
  }
  loadButtons() {
    if (this.location.name === "N/A") return;

    this.buttonType = this.getButtonType(this.location.home_banner) || "tv";
    this.buttonURL = this.getButtonURL(this.location.home_banner);
    /* // If the banner is a media (video or image)
    if (this.buttonType == "video" || this.buttonType == "image") {
      // If it is in the format 'video:https://...'
      if (btnURL) {
        this.buttonURL = btnURL;
      }
    }
    // If the banner is a tv
    else {
      this.buttonURL = undefined;
    } */
  }

  openImageDialog(path: string) {
    this.imageURL = path;
    this.helpDialog = true;
  }

  isMissingMedia = (kokote: Kokote) =>
    kokote.custom_media_type != "None" &&
    (kokote.custom_media_url == "" || kokote.custom_media_url == undefined);

  filterRooms() {
    let splitted: SplittedRoomNumber[] = [];
    this.combinedRooms = [];
    this.kokotes.forEach((kokote) => {
      // If the room number contains numbers and letters
      let multipleInRoom = kokote.room?.room_number.match(/^(\d*)([A-Za-z])$/);
      splitRoomNumber(multipleInRoom, kokote);
    });

    let combined = combineSplittedRoom(splitted);
    // Populate the `combinedRooms` array with combined rooms
    Object.entries(combined).forEach((entry: any) => {
      let combinedRoom = new CombinedRooms(Number(entry[0]), entry[1]);
      this.combinedRooms.push(combinedRoom);
    });

    function splitRoomNumber(multipleInRoom: RegExpMatchArray|null|undefined, kokote: Kokote) {
      if(multipleInRoom) {
        let roomNumber=multipleInRoom[1];
        let roomLetter=multipleInRoom[2];
        let splitted_room_number: SplittedRoomNumber={
          room_number: Number(roomNumber),
          room_letter: roomLetter,
          room: kokote,
        };
        splitted.push(splitted_room_number);
      } else {
        let splitted_room_number: SplittedRoomNumber={
          room_number: Number(kokote.room?.room_number),
          room_letter: "",
          room: kokote,
        };
        splitted.push(splitted_room_number);
      }
    }
    function combineSplittedRoom(splitted: SplittedRoomNumber[]) {
      return splitted.reduce(function(r, a) {
        r[a.room_number]=r[a.room_number]||[];
        r[a.room_number].push(a.room);
        return r;
      }, Object.create(null));
    }
  }

  multipleSelection_saveUrl(file_url: string, mediaIndex: any) {
    this.selectedRooms.forEach((kokote) => {
      kokote.custom_media_url = file_url;
      if (this.dialogMediaType == "Image") {
        this.selectedImages[this.kokotes.indexOf(kokote)] = mediaIndex;
      } else if (this.dialogMediaType == "Video") {
        this.selectedVideos[this.kokotes.indexOf(kokote)] = mediaIndex;
      }
    });
  }
  multipleSelection_type_changed() {
    this.selectedRooms.forEach((room) => {
      room.custom_media_type = this.multipleSelection_type;
      room.custom_media_url = this.multipleSelection_url;
    });
  }

  getChildRooms(kokote: Kokote) {
    let room_number = Number(kokote!.room!.room_number.match(/\d+/g)![0]);
    let childRooms = this.combinedRooms.find(
      (cr) => cr.room_number == room_number
    )?.rooms;
    return childRooms;
  }
  changeCombinedRoomMedia(item: Kokote, image_url: string) {
    let childRooms = this.getChildRooms(item);
    childRooms?.forEach((room) => {
      room.custom_media_url = image_url;
    });
  }
  radioChangedType(item: Kokote) {
    let childRooms = this.getChildRooms(item);
    childRooms?.forEach((room) => {
      room.custom_media_type = item.custom_media_type;
      room.custom_media_url = this.multipleSelection_url;
    });
  }

  openDialog(type: string, kokote: Kokote) {
    if (type !== "None") {
      this.mediaSearch = "";
      this.dialogMediaType = type;
      kokote.custom_media_type = type;
      this.dialogKokote = kokote;
      this.dialogMultiple = false;
      this.dialog = true;
      //console.log(this.currentMedia);
    }
  }
  openDialogMultiple(type: string, kokotes: Kokote[]) {
    if (type !== "None") {
      this.mediaSearch = "";
      this.dialogMediaType = type;
      kokotes.forEach((k) => (k.custom_media_type = type));
      this.dialogMultiple = true;

      this.dialog = true;
      //console.log(this.currentMedia);
    }
  }

  onSelectOneRoom(e: any) {
    let kokote: Kokote = e.item;
    let isSelected: boolean = e.value;
    let room_number = kokote.room?.room_number.match(/\d+/g)![0];
    let result = this.combinedRooms.find(
      (x) => x.room_number == Number(room_number)
    );
    if (isSelected) {
      this.multipleSelection_type = kokote.custom_media_type as CustomMediaType;

      this.selectedRooms.push(...result!.rooms);
      this.selectedRooms.forEach((room) => {
        room.custom_media_type = this.multipleSelection_type;
      });
      //console.log(this.selectedRooms);
    } else {
      result!.rooms.forEach((room) => {
        this.selectedRooms.splice(this.selectedRooms.indexOf(room), 1);
      });
      //console.log(this.selectedRooms);
    }
  }
  onSelectAllRooms(e: any) {
    let kokotes: Kokote[] = e.items;
    let isSelected: boolean = e.value;
    this.multipleSelection_type = CustomMediaType.NONE;
    kokotes.forEach((kokote) => {
      let room_number = kokote.room?.room_number.match(/\d+/g)![0];
      let result = this.combinedRooms.find(
        (x) => x.room_number == Number(room_number)
      );
      if (isSelected) {
        this.selectedRooms.push(...result!.rooms);
        this.selectedRooms.forEach((room) => {
          room.custom_media_type = this.multipleSelection_type;
          room.custom_media_url = "";
        });
      } else {
        result!.rooms.forEach((room) => {
          this.selectedRooms.splice(this.selectedRooms.indexOf(room), 1);
        });
      }
    });
    //console.log(this.selectedRooms);
  }

  //#endregion

  //#region Watchers
  @Watch("location")
  async fetchDevices() {
    this.combinedRooms = [];
    if (!this.location || this.location.name === "N/A") return;

    this.kokotes = await this.$kokotes.listFromLocation(this.location);
    this.selectedImages = new Array(this.kokotes.length).fill(0);
    this.selectedVideos = new Array(this.kokotes.length).fill(0);
    this.loadButtons();
    this.kokotes.forEach((kokote, index) => {
      if (
        kokote.custom_media_type == "Image" &&
        kokote.custom_media_url != ""
      ) {
        this.selectedImages[index] = this.images.findIndex(
          (img) => img.file_url == kokote.custom_media_url
        );
      } else if (
        kokote.custom_media_type == "Video" &&
        kokote.custom_media_url != ""
      ) {
        this.selectedVideos[index] = this.videos.findIndex(
          (vid) => vid.file_url == kokote.custom_media_url
        );
      }
    });
    this.filterRooms();
  }
  //#endregion

  //#region Computed
  get currentMedia() {
    if (this.dialogMediaType === "Image") {
      return this.images[
        this.selectedImages[this.kokotes.indexOf(this.dialogKokote)]
      ].file_url;
    } else if (this.dialogMediaType === "Video") {
      return this.videos[
        this.selectedVideos[this.kokotes.indexOf(this.dialogKokote)]
      ].file_url;
    } else {
      return "";
    }
  }
  get getAlerts() {
    return this.mainRooms.map((kokote) => this.isMissingMedia(kokote));
  }
  get filteredVideos() {
    return this.$helpers.filterVideos(this.videos, this.mediaSearch);
  }
  get filteredImages() {
    return this.$helpers.filterImages(this.images, this.mediaSearch, []);
  }
  get mainRooms() {
    return this.combinedRooms.map((combined_room) => {
      return combined_room.rooms.sort()[0];
    });
  }
  get allCombined() {
    return this.combinedRooms.reduce((acc: Kokote[], cur) => {
      return acc.concat(cur.rooms);
    }, []);
  }

  //#endregion

  async mounted() {
    await this.loadImages();

    await this.loadVideos();
    await this.fetchDevices();
  }
}
