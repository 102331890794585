
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from "vuex";

import VoucherList, { GenerationParams } from '@/components/lists/VoucherList.vue';

import { Location } from '@/entities/Location';
import { WifiVoucher } from '@/entities/WifiVoucher';
import { Watch } from 'vue-property-decorator';

@Component({
  computed: mapGetters([
    'location'
  ]),

  components: {
    VoucherList
  }
})
export default class VoucherPage extends Vue {
  search = "";
  selected = [];

  deleteDialog = false;
  alertDialog = false;
  shellDialog = false;

  alertText = "";
  shellText = "";

  fabMenu = false;

  location!: Location;
  vouchers: WifiVoucher[]=[];

  @Watch('location')
  onLocationChanged() {
    this.loadVouchers();
  }

  beforeMount() {
    this.loadVouchers()
  }
  async generate(generationParams: GenerationParams){
    const response = await this.$unifi.generateVoucher(generationParams.number, generationParams.duration, this.location.uuid, generationParams.maxUses);
    if (response) {
      this.loadVouchers();
    }
  }
  async deleteVoucher(voucher: WifiVoucher){
    this.vouchers = this.vouchers.filter((v) => v.id !== voucher.id);
    let result = await this.$unifi.deleteVoucher(voucher.id);
    if (result) {
      console.log(`Voucher ${result.code} deleted`);
    } else {
      console.log("Error deleting voucher");
    }
  }
  async save(voucher: WifiVoucher) {
    this.$unifi.updateVoucher(voucher.id, voucher).then(() => {
      this.loadVouchers()
    })
  }
  async loadVouchers(){
    this.vouchers = await this.$unifi.getVouchersByLocation(this.location.uuid);
  }
}
