
import { UnifiUrlParams } from "@/plugins/services/Unifi/unifi";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LoginOptionsInterface from "./LoginOptionsInterface";
import PasswordForm from "./PasswordForm.vue";
import RoomNumberForm from "./RoomNumberForm.vue";
import { DEFAULT_THEME, getTextColor, UnifiColorTheme } from "./UnifiColorTheme";
import VoucherForm from "./VoucherForm.vue";
@Component({
  components: {
    RoomNumberForm,
    PasswordForm,
    VoucherForm
  }
})
export default class ConnectionTypeCard extends Vue {
  @Prop({default: ""}) image_url!: string;
  @Prop({default: DEFAULT_THEME}) theme!: UnifiColorTheme;
  @Prop() loginOptions!: LoginOptionsInterface;
  @Prop() urlParams!: UnifiUrlParams;
  @Prop() free_trial_duration!: number;
  @Prop() connection_duration!: number;

  get has_image(): boolean {
    let _notEmpty = this.image_url.trim() !== "";
    let _notNull = this.image_url != undefined;
    return _notEmpty && _notNull;
  }

  get showDividers(): boolean[]{
    let _showDividers: boolean[] = [false, false];
    if(this.loginOptions.room_number && this.loginOptions.password){
      _showDividers[0] = true;
    }
    if(
      this.loginOptions.password && this.loginOptions.voucher ||
      this.loginOptions.room_number && this.loginOptions.voucher && !this.loginOptions.password
      ){
      _showDividers[1] = true;
    }

    return _showDividers;
  }

  get nbOfOptions(): number {
    let nb = 0;
    if (this.loginOptions.room_number) nb++;
    if (this.loginOptions.password) nb++;
    if (this.loginOptions.voucher) nb++;
    return nb;
  }

  get css_vars(){
    return {
      '--theme-primary': this.theme.primary,
      '--theme-accent': this.theme.accent,
      '--theme-secondary': this.theme.secondary,
      '--button-text-color': this.primaryTextColor,
    };
  }

  get primaryTextColor() {
    return getTextColor(this.theme.primary!);
  }

  freeConnection() {
    this.$emit("free-clicked");
  }
  connect(event:any){
    this.$emit("connect", event);
  }
  openTerms(){
    this.$emit("open-terms");
  }
  timeFromMinutes(totalMinutes:number) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60 % 24);
    const days = Math.floor(totalMinutes / 24 /60);

    let str = "";
    if (days>0) str += `${days} ${this.$t("captive.day")}${days>1?"s":""}`;
    if (hours>0) str += ` ${hours} ${this.$t("captive.hour")}${hours>1?"s":""}`;
    if (minutes>0) str += ` ${minutes} ${this.$t("captive.minute")}${minutes>1?"s":""}`;
    return str;
  }



}
