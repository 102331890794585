
import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import InfoForm from "@/components/forms/InfoForm.vue";
import ServicesForm from "@/components/forms/ServicesForm.vue";
import SettingsForm from "@/components/forms/SettingsForm.vue";
import WeatherForm from "@/components/forms/WeatherForm.vue";
import WelcomeForm from "@/components/forms/WelcomeForm.vue";
import WifiForm from "@/components/forms/WifiForm.vue";
import FoodBeverageForm from "@/components/forms/FoodBeverageForm.vue";
import BackupWifiForm from "@/components/forms/BackupWifiForm.vue";
import UnifiPortalForm from "@/components/forms/UnifiPortalForm.vue";
import SatisfactionForm from "@/components/forms/SatisfactionForm.vue";
import { Image } from '@/entities/images/Image';

@Component({
  components: {
    InfoForm,
    ServicesForm,
    SettingsForm,
    WeatherForm,
    WelcomeForm,
    WifiForm,
    FoodBeverageForm,
    BackupWifiForm,
    SatisfactionForm,
    UnifiPortalForm,
  },
  computed: mapGetters([
    'location'
  ])
})
export default class InfoPage extends Vue {
  images: Image[] = [];

  mounted() {
    this.loadImages();  
  }

  async loadImages() {
    this.$images.list().then((images) => {
      this.images = images;
    });
  }
}
