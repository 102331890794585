
import { UnifiUrlParams } from '@/plugins/services/Unifi/unifi';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator';

import { ConnectionFormInterface } from './ConnectionFormInterface';
import { DEFAULT_THEME, getTextColor, UnifiColorTheme } from './UnifiColorTheme';
@Component({})
export default class RoomNumberForm extends Vue implements ConnectionFormInterface {
  @Ref('form') readonly form!: (Vue & { validate: () => boolean });
  @Prop({default: DEFAULT_THEME}) theme!: UnifiColorTheme;
  @Prop() urlParams!: UnifiUrlParams;
  @Prop() connection_duration!: number;
  @Prop() free_enabled!: number;

  didAcceptTerms = false;
  invalidTerms = false;
  connectionError = false;
  errorMessage = '';
  termsClicked() {
    this.$emit("open-terms");
  }
  get link_style(){
    return {
      '--color': this.theme.secondary,
      '--color-hover': this.theme.accent

    }
  }
  valid = true;
  loginInfos = {
    type: 'room_number',
    lastName: "",
    roomNumber: "",
    email: ""
  };
  nameRules = [
    (v: string) => !!v || this.$t("captive.room.name_rules[0]"),
    (v: string) => (v && v.length >= 1) || this.$t("captive.room.name_rules[1]"),
  ];
  roomRules = [
    (v: string) => !!v || this.$t("captive.room.room_rules[0]"),
  ];
  emailRules = [
  (v: string) => !!v || this.$t("captive.room.email_rules[0]"),
  (v: string) => {
    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email_regex.test(v) || this.$t("captive.room.email_rules[1]");
  },
  ];
  get primaryTextColor() {
    return getTextColor(this.theme.primary!);
  }

  submit() {
    // console.log("submitted", this.loginInfos);
  }
  async connect(){
    this.invalidTerms = !this.didAcceptTerms;
    let valid = await this.validate();
    if(valid && this.didAcceptTerms){
      enum Results{
        Success = 0,
        Error = 1,
        EmailAlreadyValidated = 2,
      }
      let res = await this.$unifi.sendConnectionEmail(this.loginInfos.email, 120, this.urlParams.id, this.urlParams.ap, false, this.urlParams.siteID);
      if (res==Results.Success) {
        alert(this.$t("captive.email_sent"));
        this.$emit("connect", {minutes: 120, email_validation: true});
      }
      else if (res==Results.EmailAlreadyValidated) {
        // Duration is in minutes but connection duration is in days
        this.$emit("connect", {minutes:this.connection_duration * 24 * 60, email_validation:false});

      }
      else {
        alert(this.$t("captive.error.generic"));
      }
    }
  }

  async validate() {
    let validForm = this.form.validate();
    if (!validForm || this.invalidTerms)return false;

    let res = await this.$unifi.validateRoomNumberLogin(this.urlParams.siteID,this.loginInfos.roomNumber, this.loginInfos.lastName)
    if(!res){
      this.connectionError = true;
      this.errorMessage = this.$t("captive.invalid1") as string;
      if(this.free_enabled) this.errorMessage += this.$t("captive.invalid2") as string;
     return false;
    }
    return validForm&&res;
  }
}
