
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';

import draggable from 'vuedraggable'

import { ServiceDetail } from '@/entities/directory/ServiceDetail';
import { WifiVoucher } from '@/entities/WifiVoucher';

export interface GenerationParams {
  number: number,
  maxUses: number|null,
  duration: number,
  unlimited_uses: boolean
}

@Component({
  components: {
    draggable
  }
})
export default class VoucherList extends Vue {
  @PropSync('vouchers', { type: Array })
  syncVouchers!: WifiVoucher[];
  editDialog = false;
  generateDialog = false;
  generationParams: GenerationParams = {
    number: 5,
    maxUses: 2,
    duration: 60,
    unlimited_uses: false
  };
  editedItem = {} as WifiVoucher;
  deleteDialog = false;
  get headers() {
    return [
      {
        text: 'Code',
        align: 'start',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Duration (min)',
        value: 'duration',
      },
      { text: 'Max uses', value: 'usages', },
      { text: 'Current uses', value: 'current_usages' },
      { text: 'Creation date', value: 'created_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ]
  }


  voucherRowClass(item: WifiVoucher) {
    if (item.usages && item.usages >= 0 && item.current_usages >= item.usages) {
      return 'strikeout'
    }

  }
  openGenerate(){
    this.generateDialog = true;
  }
  closeGenerate(){
    this.generateDialog = false;
  }
  closeEdit() {
    this.editDialog = false
  }
  save() {
    this.$emit('save', this.editedItem)
    this.closeEdit()
  }
  generate() {
    if (!this.generationParams.number || this.generationParams.number <= 0) {
      alert('Number of vouchers is invalid');
      return;
    }
    if(!this.generationParams.duration || this.generationParams.duration <= 0){
      alert('Duration is invalid');
      return;
    }
    if (this.generationParams.unlimited_uses) {
      this.generationParams.maxUses = null;
    }
    this.$emit('generate', this.generationParams)
    this.closeGenerate()
  }
  editItem(item: WifiVoucher) {
    this.editedItem = item;
    this.editDialog = true;
  }
  deleteItem(item: WifiVoucher) {
    this.editedItem = item;
    this.deleteDialog = true;
  }
  closeDelete() {
    this.deleteDialog = false
  }
  deleteItemConfirm() {
    this.$emit('delete', this.editedItem)
    this.closeDelete()
  }
}
