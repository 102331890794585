
import { WifiVoucher } from '@/entities/WifiVoucher';
import { UnifiUrlParams } from '@/plugins/services/Unifi/unifi';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator';
import { ConnectionFormInterface } from './ConnectionFormInterface';
import { getTextColor, UnifiColorTheme } from './UnifiColorTheme';
@Component({})
export default class VoucherForm extends Vue implements ConnectionFormInterface  {
  @Prop() urlParams!: UnifiUrlParams;
  @Ref('form') readonly form!: (Vue & { validate: () => boolean });
  @Prop() theme!: UnifiColorTheme;
  didAcceptTerms = false;
  invalidTerms = false;
  connectionError = false;
  errorMessage = '';
  wifi_voucher?: WifiVoucher;
  get link_style(){
    return {
      '--color': this.theme.secondary,
      '--color-hover': this.theme.accent
    }
  }
  get primaryTextColor() {
    return getTextColor(this.theme.primary!);
  }
  voucherRules = [
    (v: string) => !!v || this.$t("captive.voucher.rules[0]"),
    (v: string) => (v && v.length >= 1) || this.$t("captive.voucher.rules[1]"),
  ];
  termsClicked() {
    this.$emit("open-terms");
  }
  loginInfos = {
    voucher: ""
  };
  submit() {
    // console.log("submitted", this.loginInfos);
  }
  async validate() {
    let validForm = this.form.validate();
    if (!validForm || this.invalidTerms)return false;
    let res = await this.$unifi.validateVoucher(this.urlParams.siteID, this.loginInfos.voucher);
    if(!res){
      this.connectionError = true;
      this.errorMessage = this.$t("captive.voucher.invalid") as string;
     return false;
    }
    if(validForm&&!!res) this.wifi_voucher = res;
    return validForm&&!!res;
  }
  async connect(){
    this.invalidTerms = !this.didAcceptTerms;
    let valid = await this.validate();
    if(valid && this.didAcceptTerms && this.wifi_voucher){
      this.$unifi.useVoucher(this.urlParams.siteID, this.wifi_voucher.code);
      this.$emit("connect", {minutes:this.wifi_voucher.duration, email_validation:false});
    }
  }
}
