
import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { categories } from '@/constants';

import { Image } from '@/entities/images/Image';
import { Location } from '@/entities/Location';
import { User } from '@/entities/User';

@Component({
  computed: mapGetters([
    'location',
    'user'
  ])
})
export default class ImageUploaderDialog extends Vue {
  @Prop()
  value!: Boolean;

  @Prop({ default: false })
  editing!: boolean;

  @Prop({ default: null })
  image!: Image | null

  @Prop({ default: null })
  asCategory?: string | null;

  @Prop({ default: () => {}})
  refresh!: () => {}

  valid = false;
  categories: string[] = [];

  name?: string = "";
  nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string)  => (v && v.length >= 6) || 'Name must be more than 5 characters',
    (v: string)  => (v && v.length <= 64) || 'Name must be less than 65 characters'
  ];

  category = "";
  categoryRules = [
    (v: string) => !!v || 'Category is required'
  ];
  
  file: File | null = null;
  fileRules = [
    (v: string)  => !!v || 'Image is required',
    (v: File) => {
      const MbInBytes = 1024**2 // 1 Mb
      if(v && v.size > (MbInBytes*10) /** 10Mb */ )
      {
        return 'Image is larger than 10 Mb'
      }
      else{
        return true;
      }
    }
  ];

  global = false;

  location!: Location;
  user!: User;

  $refs!: {
    form: HTMLFormElement
  }

  @Watch("value")
  valueChanged() {
    if (this.value) {
      if(this.ofSpecificCategory) {
        this.category = this.asCategory!;
      } else {
        this.categories = categories;
      }
    }
  }

  get show() {
    return this.value;
  }

  set show(value) {
    if (this.image === null) {
      this.$refs.form.reset();
      this.name = "";
      this.category = "";
      this.file = null;
    }
    
    this.$emit('input', value);
  }

  get ofSpecificCategory(){
    return this.asCategory!=null && categories.includes(this.asCategory);
  }

  @Watch("image")
  imageChanged() {
    this.name = this.image!.name;
    this.file = new File([""], this.image!.file_name);
  }

  click() {
    if (this.image === null) {
      this.save();
    } else {
      this.edit();
    }
  }

  onChange(file: File) {
    if (this.name === undefined) return;

    if (this.name.trim() === "") {
      this.name = file.name.split('.').slice(0, -1).join('.');
    }
  }

  async save() {
    if (this.$refs.form.validate()) {
      const form = this.$refs.form.$el;
      const data = new FormData(form);

      if (this.global === false) {
        data.set("location", this.location.uuid);
      }
      if (this.ofSpecificCategory) {
        data.set("category", this.category);
      }
      if (data.get("category")=="Banner"/* ||data.get("category")=="Logo"*/) {
        this.$images.uploadAndResize(data, 1920, 1080, true).then(() => {
        this.show = false;
        this.refresh();
      });
      } else {
        this.$images.upload(data).then(() => {
        this.show = false;
        this.refresh();
      });
      }
    }
  }

  async edit() {
    if (this.$refs.form.validate()) {
      this.$images.edit(this.image!.file_name, {
        name: this.name,
      }).then(() => {
        this.show = false;
        this.refresh();
      });
    }
  }
}
